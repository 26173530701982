<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>
      <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-album-2 text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">Contract</h5>

                <p class="mb-0 font-weight-bold text-sm"></p>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <form id="form" role="form" method="POST">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">{{message}}</p>
                <argon-button  v-if="isFYMAdmin" color="success" size="sm" class="ms-auto" @click.prevent="submitData()"
                  >Save</argon-button
                >
                <router-view></router-view>
              </div>
            </div>
            <div class="card-body">
              
              <div class="row">

              <div class="col-md-6">
                  <label for="rate" class="form-control-label"
                    >Carrier
                  </label><br>

                  <select id="carrier" name="carrier" class="form-select" v-model="selectedCarrier" :value="selectedCarrier">
                    <option v-for="element in carriers" :value="element.id" :key="element.id">
                      {{ element.name }}
                    </option>
                  </select>
                                 
              </div>
              <div class="col-md-6">
                
              </div>

                <div class="col-md-6">
                  <label for="contract_type" class="form-control-label"
                    >Contract Type</label
                  >
                  <input class="form-control form-control-default invalid" id="contract_type" name="contract_type" type="text" :value="elements[0].contract_type"/>
                </div>

                <div class="col-md-6">
                  <label for="contract_description" class="form-control-label"
                    >Contract Description</label
                  >
                  <input class="form-control form-control-default invalid" id="contract_description" name="contract_description" type="text" :value="elements[0].contract_description"/>
                </div>

                <div class="col-md-6">
                  <label for="rate_type" class="form-control-label"
                    >Rate type
                  </label><br>
                  <span>
                    <select id="rate_type" name="rate_type" class="form-select" v-model="selectedRateType" :value="selectedRateType">
                      <option v-for="element in rate_types" :value="element.id" :key="element.id">
                        {{ element.name }}
                      </option>
                    </select>
                  </span>
                </div>

                <div class="col-md-6">
                  <label for="rate" class="form-control-label"
                    >Rate</label
                  >
                  <input class="form-control form-control-default invalid" id="rate" name="rate" type="text" :value="elements[0].rate"/>
                </div>

                <div class="col-md-6">
                  <label for="effective_from" class="form-control-label"
                    >Effective from</label
                  >
                  <input class="form-control form-control-default invalid" id="effective_from" name="effective_from" type="date" :value="elements[0].effective_from"/>
                </div>

                <div class="col-md-6">
                  <label for="effective_to" class="form-control-label"
                    >Effective to</label
                  >
                  <input class="form-control form-control-default invalid" id="effective_to" name="effective_to" type="date" :value="elements[0].effective_to"/>
                </div>

            </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </section>
  </main>
</template>

<script>
import router from "@/router";
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";

var selectedCarrier;
var selectedRateType;

var message = "Contract";

export default {
  name: "Contract",
  props: ['id'],
  data() {
    return {
      showMenu: false,
      elements: [{id:0}],
      carriers: [],
      rate_types: [],
      selectedCarrier,
      selectedRateType,
      message,
      role: sessionStorage.getItem('role')
    }
  },
  computed: {
    formattedDate(dt) {
      return new Date(dt).toLocaleDateString();
    },
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    serializeForm(){
      
      let myForm = document.getElementById('form'); 
      let formData = new FormData(myForm);
      const data = {}; 
      // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
      }
      return(data);
    },
    async checkAuth() {
      let response = await fetch("/apis/auth");
      this.loggedin = await response.json();
      if(this.loggedin =="notloggedin") {
        location.href="/home";
      }
    },
    async getData() {
        let response = await fetch("/apis/element_load/at_contracts/" + this.$route.params.id);
        this.elements = await response.json();
        this.selectedCarrier = this.elements[0]['carrier_id']; 
        this.selectedRateType = this.elements[0]['rate_type_id']; 
    },
    async getLookups() {        
      let response1 = await fetch("/apis/view/Carriers");
      this.carriers = await response1.json();
      
      let response2 = await fetch(this.$server + '/apis/get_lu/rate_types');
      this.rate_types = await response2.json();
    },
    submitData: function() {
      
      var action = 'create';
      var api = 'create';
      var element_id = this.$route.params.id;

      if (element_id > 0) {
        action = 'update';
        api = 'update/' + element_id;
      }

      fetch(this.$server + '/apis/Contract_' + api, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.serializeForm()),
     })
     
      .then((res) => res.json())
      .then((data) => {
        if (data.result === 'Contract_' + action + 'd') {
          this.message = data.message;
          this.getData();
        }
        else {
          router.push('/');
        }
      })
      .catch((err) => console.log(err));
    },
  },
  components: { Navbar,  ArgonButton },
  created() {

    this.checkAuth();
    
    this.getLookups();

    if (this.$route.params.id > 0) {
      this.getData();    
    }
    else {
      this.elements[0].id = '0';      
    }

  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>