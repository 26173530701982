import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ArgonDashboard from "./argon-dashboard";
import vue3GoogleLogin from 'vue3-google-login'
import "./assets/css/nucleo-icons.css";
import "./assets/css/fym-custom.css";
import "./assets/css/fontawesome.css";

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);

appInstance.use(vue3GoogleLogin, {
  clientId: '823666176651-94nlrhp8jsbal4bt0t5nvlgfqt4lopq3.apps.googleusercontent.com'
})

appInstance.config.globalProperties.$version = '1.0.1';

var hostname = "";

if (process.env.VUE_APP_PLATFORM === 'Jasons-MacBook-Pro.local') {
  hostname = 'localhost';
  appInstance.config.globalProperties.$server = "http://" + hostname + ":3000";
}
else if(process.env.VUE_APP_PLATFORM === 'dev-k8s.teamfym.com') {
  hostname = 'dev-k8s.teamfym.com';
  appInstance.config.globalProperties.$server = "https://" + hostname;
} 
else {
  hostname = 'dev.teamfym.com'
  appInstance.config.globalProperties.$server = "https://" + hostname;
}

appInstance.mount("#app");