<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-album-2 text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">Override</h5>

                <p class="mb-0 font-weight-bold text-sm"></p>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <form id="form" role="form" method="POST">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">{{message}}</p>
                <argon-button color="success" size="sm" class="ms-auto" @click.prevent="submitData()"
                  >Save</argon-button
                >
                <router-view></router-view>
              </div>
            </div>
            <div class="card-body">
              
              <div class="row">

              <div class="col-md-10">
                  <label for="rate" class="form-control-label"
                    >Amount
                  </label><br>
                  <span class="td11">
                    <input class="form-control form-control-default invalid" id="amount" name="amount" type="number" :value="elements[0].amount"/>
                  </span>
                  
              </div>

              <div class="col-md-10">
                  <label for="rate" class="form-control-label"
                    >Paid to
                  </label><br>
                  <span class="td11">
                    <input class="form-control form-control-default invalid" id="paid_to" name="paid_to" type="text" :value="elements[0].paid_to"/>
                  </span>
                  
              </div>

              <div class="col-md-10">
                  <label for="rate" class="form-control-label"
                    >Effective From Date
                  </label><br>
                  <span class="td11">
                    <input class="form-control form-control-default invalid" id="effective_from" name="effective_from" type="date" :value="elements[0].effective_from"/>
                  </span>
                  
              </div>

              <div class="col-md-10">
                  <label for="rate" class="form-control-label"
                    >Effective To Date
                  </label><br>
                  <span class="td11">
                    <input class="form-control form-control-default invalid" id="effective_to" name="effective_to" type="date" :value="elements[0].effective_to"/>
                  </span>
                  
              </div>
            </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import router from "@/router";
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";

var selectedProduct;
var selectedRateType;
var rate = [];
var agency_rate = 0;
var agency_rate_type = '$';

var message = "Override";

export default {
  name: "Override",  
  props: ['id','agent_id'],
  data() {
    return {
      showMenu: false,
      elements: [{id:0}],
      rate_types: [],
      selectedRateType,
      products: [],
      selectedProduct,
      rate,
      agency_rate,
      agency_rate_type,
      message
    }
  },
  computed: {
    formattedDate(dt) {
      return new Date(dt).toLocaleDateString();
    },
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    serializeForm(){
      
      let myForm = document.getElementById('form'); 
      let formData = new FormData(myForm);
      const data = {}; 
      // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
      }
      return(data);
    },
    async checkAuth() {
      let response = await fetch("/apis/auth");
      this.loggedin = await response.json();
      if(this.loggedin =="notloggedin") {
        location.href="/home";
      }
    },
    async getData() {
        let response = await fetch("/apis/element_load/at_overrides/" + this.$route.params.id);
        this.elements = await response.json();
        this.selectedProduct = this.elements[0]['product_id'];
        this.selectedRateType = this.elements[0]['rate_type_id'];       
    },
    async getLookups() {
        let response = await fetch(this.$server + '/apis/get_lu/products');
        this.products = await response.json();

        let response2 = await fetch(this.$server + '/apis/get_lu/rate_types');
        this.rate_types = await response2.json();

        let res3 = await fetch(this.$server + '/apis/get_agency_rate');
        this.rate = await res3.json();
        
        this.agency_rate = this.rate[0]['rate'];
        this.agency_rate_type = this.rate[0]['rate_type'];

    },
    validateForm() {
      var val = true;

      const items = ['amount'];

      for (const item of items) {

        if(document.getElementById(item).value === '') {

          var pcit = this.convertToTitleCase(item).replace('_',' ');

          var msg = 'Please enter a value in the ' + pcit + ' field';
          document.getElementById(item).focus();
          this.message = msg;
          val = false;
        }

        if(document.getElementById(item).value > Number(this.agency_rate)) {

          var pcit = this.convertToTitleCase(item).replace('_',' ');

          var msg = 'Override amount cannot be above Agency rate of ' + this.agency_rate;
          document.getElementById(item).focus();

          this.message = msg;
          val = false;
        }

      }

      return val;

    },
    convertToTitleCase(str) {
      if (!str) {
          return ""
      }

      return str.toLowerCase().split(' ').map(function (word) {
        return word.charAt(0).toUpperCase().concat(word.substr(1));
      }).join(' ');
    },
    submitData: function() {
           
      if (this.validateForm()) {
        
        var action = 'create';
        var api = 'create/' + this.$route.params.agent_id;

        if (this.$route.params.id > 0) {
          action = 'update';
          api = 'update/'  + this.$route.params.id;
        }

        fetch(this.$server + '/apis/Override_' + api, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(this.serializeForm()),
        })
      
        .then((res) => res.json())
        .then((data) => {
          if (data.result === 'Override_' + action + 'd') {
            this.message = data.message;
            router.push('/Agent/' + this.$route.params.agent_id);

          }
          else {
            router.push('/');
          }
        })
        .catch((err) => console.log(err));
    }
      else {
        console.log('Override form validation failed.');
      }
    },
  },
  components: { Navbar,  ArgonButton },
  created() {

    this.checkAuth();
    
    if (this.$route.params.id > 0) {
      this.getData();
    }
    else {
      this.elements[0].id = "0";
    }

    this.getLookups();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>