<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>
    <div class="py-4 container-fluid">
      
      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-album-2 text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1"> Agent {{elements[0].firstname}} {{elements[0].lastname}} </h5>

                <p class="mb-0 font-weight-bold text-sm"> </p>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <form id="profile-form" role="form" method="POST" enctype="multipart/form-data">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p id="message" name="message" class="text-dark font-weight-bolder">{{this.message}}</p>
                <argon-button color="success" size="sm" class="ms-auto" @click.prevent="submitData()">Save</argon-button>
              </div>
            </div>
            <div class="card-body">
              <p class="text-uppercase text-sm">Agent Information</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="email" class="form-control-label"
                    >Email address</label
                  >
                  <input class="form-control form-control-default invalid" id="email" name="email" type="email" :value="elements[0].email" autocomplete="email" required/>
                </div>
                <div class="col-md-6">
                  <label for="carrier" class="form-control-label"
                    >Carrier</label
                  >
                  <select id="carrier" name="carrier" class="form-select" :value="selectedCarrier">
                    <option v-for="element in carriers" :value="element.id" :key="element.id">
                      {{ element.name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-6">
                  <label for="personal_email" class="form-control-label"
                    >Personal Email</label
                  >
                  <input class="form-control form-control-default invalid" id="personal_email" name="personal_email" type="text" :value="elements[0].personal_email"/>
                </div>
                <div class="col-md-6">    
                  <label for="agent_manager" class="form-control-label"
                    >Agent Manager</label
                  >
                  <select id="agent_manager" name="agent_manager" class="form-select" :value="selectedAgentManager">
                      <option v-for="element in agent_managers" :value="element.id" :key="element.id">
                        {{ element.name }}
                      </option>
                    </select>             
                </div>

                <div class="col-md-6">
                  <label for="firstname" class="form-control-label"
                    >First name</label
                  >
                  <input class="form-control form-control-default invalid" id="firstname" name="firstname" type="text" :value="elements[0].firstname"/>
                </div>
                <div class="col-md-6">
                  <label for="lastname" class="form-control-label"
                    >Last name</label
                  >
                  <input class="form-control form-control-default invalid" id="lastname" name="lastname" type="text" :value="elements[0].lastname"/>
                </div>
                
                <div class="col-md-6">
                  <label for="nick_name" class="form-control-label"
                    >Nick Name</label
                  >
                  <input class="form-control form-control-default invalid" id="nick_name" name="nick_name" type="text" :value="elements[0].nick_name"/>
                </div>
                <div class="col-md-6">
                  <label for="agent_npn" class="form-control-label"
                    >Agent NPN</label
                  >
                  <input class="form-control form-control-default invalid" id="agent_npn" name="agent_npn" type="text" :value="elements[0].agent_npn" required/>
                </div>

                <div class="col-md-6">
                  <label for="agent_type" class="form-control-label"
                    >Agent Type</label
                  >
                  <span class="td6">
                    <select id="agent_type" name="agent_type" class="form-select" :value="selectedAgentType">
                      <option v-for="element in agent_types" :value="element.id" :key="element.id">
                        {{ element.name }}
                      </option>
                    </select>
                  </span>
                </div>
                <div class="col-md-6">
                  <label for="agent_status" class="form-control-label"
                    >Status</label
                  >
                  <span class="td6">
                    <select id="agent_status" name="agent_status" class="form-select" :value="selectedStatus">
                      <option v-for="element in statuses" :value="element.id" :key="element.id">
                        {{ element.name }}
                      </option>
                    </select>
                  </span>
                </div>

                <div class="col-md-6">
                  <label for="dialer" class="form-control-label"
                    >Dialer</label
                  >
                  
                  <select id="dialer" name="dialer" class="form-select" :value="selectedDialer">
                      <option v-for="element in dialers" :value="element.id" :key="element.id">
                        {{ element.name }}
                      </option>
                    </select>

                </div>
                <div class="col-md-6">
                  <label for="agent_level" class="form-control-label"
                    >Agent Level</label
                  >
                    <select id="agent_level" name="agent_level" class="form-select" :value="selectedAgentLevel">
                      <option v-for="element in agent_levels" :value="element.id" :key="element.id">
                        {{ element.name }}
                      </option>
                    </select>
                </div>

                <div class="col-md-6">
                  <label for="start_date" class="form-control-label"
                    >Start Date</label
                  >
                  <input class="form-control form-control-default invalid" id="start_date" name="start_date" type="date" :value="elements[0].start_date"/>
                </div>
                <div class="col-md-6">
                  <label for="end_date" class="form-control-label"
                    >End Date</label
                  >
                  <input class="form-control form-control-default invalid" id="end_date" name="end_date" type="date" :value="elements[0].end_date"/>
                </div>
               
                <div class="col-md-6">
                  <label for="phone" class="form-control-label"
                    >Agent Phone</label
                  >
                  <input class="form-control form-control-default invalid" id="phone" name="phone" type="text" :value="elements[0].phone" autocomplete="tel"/>
                </div>
                <div class="col-md-6">
                  <label for="sales_type" class="form-control-label"
                    >Sales Type</label
                  >
                  <span class="td6">
                  <select id="sales_type_id" name="sales_type_id" class="form-select" :value="selectedSalesType">
                      <option v-for="element in sales_types" :value="element.id" :key="element.id">
                        {{ element.name }}
                      </option>
                    </select>
                  </span>
                </div>

                <div class="col-md-6">
                  <label for="street" class="form-control-label"
                    >Street</label
                  >
                  <input class="form-control form-control-default invalid" id="street" name="street" type="text" :value="elements[0].street"/>
                </div>
                <div class="col-md-6">
                  <label for="city" class="form-control-label"
                    >City</label
                  >
                  <input class="form-control form-control-default invalid" id="city" name="city" type="text" :value="elements[0].city"/>
                </div>

                <div class="col-md-6">
                  <label for="usstate" class="form-control-label"
                    >State</label
                  >
                  <span class="td6">
                    <select id="usstate_id" name="usstate_id" class="form-select" :value="selectedUSState" required>
                      <option v-for="element in usstates" :value="element.id" :key="element.id">
                        {{ element.name }}
                      </option>
                    </select>
                  </span>
                </div>
                <div class="col-md-6">
                  <label for="zip" class="form-control-label"
                    >Zip</label
                  >
                  <input class="form-control form-control-default invalid" id="zip" name="zip" type="text" :value="elements[0].zip"/>
                </div>

                <div class="col-md-6">
                  <label for="ein" class="form-control-label"
                    >EIN</label
                  >
                  <input class="form-control form-control-default invalid" id="ein" name="ein" type="text" :value="elements[0].ein"/>
                </div>
                <div class="col-md-6">
                </div>

                <div class="col-md-6">
                  <label for="san" class="form-control-label"
                    >SAN</label
                  >
                  <input class="form-control form-control-default invalid" id="san" name="san" type="text" :value="elements[0].san"/>
                </div>
                <div v-if="isFYMAdmin" class="col-md-6">
                </div>

                <div class="col-md-6">                  
                </div>
                <div class="col-md-6">
                </div>

                <div>
                  <hr>
                </div>
                
                <div class="card-header pb-0">
                  <div class="d-flex align-items-center">
                    <p id="message" name="message" class="text-dark font-weight-bolder">Agent Rates</p>
                    <argon-button color="success" size="sm" class="ms-auto" @click.prevent="newelement('AgentRate')">Create Agent Rate</argon-button>
                  </div>
                    <ul class="ul">  
                      <li v-for="element in my_rates" :value="element.id" :key="element.id">

                        <span class="td1">

                          <!-- Conditional green dot for current rate -->
                          <img v-if="element.rate_status === 'current'" src="/img/green.jpg" alt="Current rate" style="width: 10px; height: 10px; margin-right: 5px;">
                          <img v-else-if="element.rate_status === 'future'" src="/img/blue.jpg" alt="Future rate" style="width: 10px; height: 10px; margin-right: 5px;">
                          <img v-else src="/img/orange.jpg" alt="Old rate" style="width: 10px; height: 10px; margin-right: 5px;">

                          <router-link :to="{ name: 'AgentRate', params: {id: element.id, agent_id: this.$route.params.id}}" class="font-weight-bold">
                            <i class="fa fa-ellipsis-v text-xs"></i>
                            &nbsp;<a
                            href="#"
                            class="text-secondary font-weight-bold text-xs"
                            data-toggle="tooltip"                   
                            > Edit</a>
                          </router-link>
                        </span>                         
                        {{ element.rate_type }} {{ element.rate }} for product: {{ element.product }} {{ new Date(element.effective_from).toLocaleDateString() }} - {{ new Date(element.effective_to).toLocaleDateString() }}

                      </li>
                    </ul>
                </div>

                <div>
                  <hr>
                </div>
                
                <div class="card-header pb-0">
                  <div class="d-flex align-items-center">
                    <p id="message" name="message" class="text-dark font-weight-bolder">Overrides</p>
                    <argon-button color="success" size="sm" class="ms-auto" @click.prevent="newelement('Override')">Create Override</argon-button>
                  </div>
                    <ul class="ul">  
                      <li v-for="element in my_overrides" :value="element.id" :key="element.id">
                        
                        <span class="td1">
                          <router-link :to="{ name: 'Override', params: {id: element.id, agent_id: this.$route.params.id}}" class="font-weight-bold">
                            <i class="fa fa-ellipsis-v text-xs"></i>
                            &nbsp;<a
                            href="#"
                            class="text-secondary font-weight-bold text-xs"
                            data-toggle="tooltip"                   
                            > Edit</a>
                          </router-link>
                        </span>
                        $ {{ element.amount }} paid to: {{ element.paid_to }}

                      </li>
                    </ul>
                </div>

              </div>

            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
    </section>
  </main>
</template>

<script>

import router from "@/router";
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";

import ArgonButton from "@/components/ArgonButton.vue";
import { readonly } from "vue";

const body = document.getElementsByTagName("body")[0];

var message = "New Agent";
const username = sessionStorage.getItem("username");

var selectedUSState = 43;
var selectedCarrier = 1;
var selectedAgency = sessionStorage.getItem("agency_id");
var selectedAgentType = 1;
var selectedStatus = 1;
var selectedSalesType = 1;
var selectedAgentLevel = 1;
var selectedAgentManager = 1;
var selectedDialer = 1;

export default {
  name: "agent_profile",
  props: ['id'],
  data() {
    return {
      showMenu: false,
      elements: [{id:0}],
      agent_managers: [],
      selectedAgentManager,
      carriers: [],
      selectedCarrier,
      agencies: [],
      selectedAgency,
      agent_types: [],
      selectedAgentType,
      statuses: [],
      selectedStatus,
      sales_types: [],
      selectedSalesType,
      usstates: [],
      selectedUSState,
      agent_levels: [{'id':1,'name':'Base'}, {'id':2,'name':'Medium'}, {'id':3,'name':'High'}, {'id':0,'name':'Undefined'}],
      selectedAgentLevel,
      my_rates: [],
      my_overrides: [],
      message,
      username,
      dialers: [{'id':1,'name':'Convoso'}, {'id':2,'name':'External FYM'}, {'id':3,'name':'Internal IFG'}, {'id':0,'name':'Undefined'}],
      selectedDialer,
      role: sessionStorage.getItem('role'),
      form: {
        method: '',
        icon: ''
      },
      token: '',
      fetchResponse: {},
      fetchError: '',
      isFieldDisabled: true,
      errors: {},
    }
  },
  computed: {
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    serializeForm(){
      
      let myForm = document.getElementById('profile-form'); 
      let formData = new FormData(myForm);
      const data = {}; 

      // Temporarily enable the field
      this.isFieldDisabled = false;

      for (let [key, val] of formData.entries()) {
        Object.assign(data, {[key]: val});
      }
      
      // Optionally re-disable the field
      this.isFieldDisabled = true;

      return data;
    },
    async checkAuth() {
      let response = await fetch("/apis/auth");
      this.loggedin = await response.json();
      if(this.loggedin =="notloggedin") {
        location.href="/home";
      }
    },
    async getData(agent_id) {
      let response = await fetch("/apis/element_load/at_agents/" + agent_id);
      this.elements = await response.json();
        
      this.selectedCarrier = this.elements[0]['carrier_id']; 
      this.selectedAgentType = this.elements[0]['agent_type_id']; 
      this.selectedStatus = this.elements[0]['agent_status_id']; 
      this.selectedSalesType = this.elements[0]['sales_type_id']; 
      this.selectedUSState = this.elements[0]['usstate_id']; 
      this.selectedAgentManager = this.elements[0]['agent_manager_id'];
      this.selectedDialer = this.elements[0]['dialer']; 
      this.selectedAgentLevel = this.elements[0]['agent_level']; 

    },
    async getAgentRates() {        
      let response = await fetch("/apis/view/AgentRates/" + this.$route.params.id);
      this.my_rates = await response.json();
    },
    async getOverrides() {        
      let response = await fetch("/apis/view/Overrides/" + this.$route.params.id);
      this.my_overrides = await response.json();
    },
    async newelement(type) {
      router.push({ name: type, params: {id: 0, agent_id: this.$route.params.id}});
    },
    async getLookups() {

      let response1 = await fetch("/apis/view/Carriers");
      this.carriers = await response1.json();

      let response2 = await fetch("/apis/get_lu/agencies");
      this.agencies = await response2.json();

      let response3 = await fetch("/apis/get_lu/agent_types");
      this.agent_types = await response3.json();

      let response4 = await fetch("/apis/get_lu/agent_statuses");
      this.statuses = await response4.json();

      let response5 = await fetch("/apis/get_lu/sales_types");
      this.sales_types = await response5.json();

      let response6 = await fetch("/apis/get_lu/usstates");
      this.usstates = await response6.json();
        
      let response7 = await fetch("/apis/AgentManagers");
      this.agent_managers = await response7.json();

    },
    validateForm() {
      var val = true;

      const items = ['email'];

      for (const item of items) {

        if(document.getElementById(item).value === '') {

          var pcit = this.convertToTitleCase(item).replace('_',' ');

          var msg = 'Please enter a value in the ' + pcit + ' field';
          document.getElementById(item).focus();
          this.message = msg;
          val = false;
        }

      }

      return val;
      
    },
    convertToTitleCase(str) {
      if (!str) {
          return ""
      }

      return str.toLowerCase().split(' ').map(function (word) {
        return word.charAt(0).toUpperCase().concat(word.substr(1));
      }).join(' ');
    },
    validEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    submitData: function() {

      if (this.validateForm()) {

        var action = 'create';
        var api = 'create';
        var element_id = this.$route.params.id;

        if (element_id > 0) {
          action = 'update';
          api = 'update/' + element_id;
        }

        fetch(this.$server + '/apis/Agent_' + api, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(this.serializeForm()),
        })

        .then((res) => res.json())
        .then((data) => {
          if (data.result === 'Agent_' + action + 'd') {
            this.message = data.message;            
            this.getData(data.agent_id);
          }
          else {
            //router.push('/');
          }
        })
        .catch((err) => console.log(err));
      }
      else {
        console.log('Agent form validation failed.');
      }

    },
    sendFetch() {

      let formData = new FormData();
      formData.append("file", document.forms[0].file.files[0]);

      fetch( '/apis/file_upload', {
          method: 'POST',
          headers: {
              //'Authorization': 'Bearer '+this.token,
              //'Accept': 'application/json',
              //'Content-Type': 'multipart/form-data'
          },
          body: formData
      } )
      .then( function( response ){
          if( response.status != 201 ){
            throw response.status;
          }else{
            return response.json();
          }
      }.bind(this))
      .then( function( data ){
          this.fetchResponse = data;
      }.bind(this))
      .catch( function( error ){
          this.fetchError = error;
      }.bind(this));
        },
        handleFileUpload( e ){
          //console.log(e.target.files[0]);
          this.form.icon = e.target.files[0];
    }
  },
  components: { Navbar,  ArgonButton },
  created() {

    this.checkAuth();
    
    if (this.$route.params.id > 0) {
      this.getData(this.$route.params.id);
      this.getAgentRates();
      this.getOverrides();
    }
    else {
      this.elements[0].id = "0";
    }

    this.getLookups();
    
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    //setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
  
};
</script>
