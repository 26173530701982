<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>

    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">  
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-collection text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{company}} Agents</h5>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>                
                <argon-button color="success" size="sm" class="ms-auto" @click.prevent="newelement('Agent')">Create Agent</argon-button>
              </div>
            </div>
            <div class="card-body">
              
              <div class="col-md-12">
                <form id="search-form" role="form" method="POST" enctype="multipart/form-data" @submit.prevent="search()">                  
                  <a id="search_link" name="search_link" href=# @click.prevent="search()" title="search" hidden><i class="ni ni-zoom-split-in text-info text-sm opacity-10"></i></a>
                  <input id="search" name="search" type="text" class="form-control form-control-default invalid" title="Search" placeholder="Search" @keyup.prevent="search()">
                </form>
              </div>

              <ul class="ul">
                  <li>
                    <span class="td1"> 
                    </span>

                    <span class="td1 heading">
                      Agent NPN
                    </span>
                       
                    <span class="td2 heading">
                      Firstname
                    </span>

                    <span class="td2 heading">
                      Lastname
                    </span>

                    <span v-if="isFYMAdmin" class="td1 heading">
                      Agency
                    </span>

                    <span class="td1 heading">
                      Agent Type
                    </span>

                    <span class="td1 heading">
                      Status
                    </span>

                    <span class="td1 heading">
                      Agent rate
                    </span>

                    <span class="td2 heading">
                      Phone
                    </span>

                  </li>
              </ul>

              <ul class="ul" v-for="element in elements" v-bind:key="element.id">
                  <li>
                    <span class="td1">
                      <router-link :to="{ name: 'Agent', params: {id: element.id}}" class="font-weight-bold">
                        <i class="fa fa-ellipsis-v text-xs"></i>
                        &nbsp;<a
                        href="#"
                        class="text-secondary font-weight-bold text-xs"
                        data-toggle="tooltip"                   
                        > Edit</a>
                      </router-link>
                    </span>
                        
                    <span class="td1">
                      {{element.agent_npn}}
                    </span>
                       
                    <span class="td2">
                      {{element.firstname}}
                    </span>

                    <span class="td2">
                      {{element.lastname}}
                    </span>

                    <span v-if="isFYMAdmin" class="td1">
                      {{element.agency}}
                    </span>

                    <span class="td1">
                      {{element.agent_type}}
                    </span>

                    <span class="td1">
                      {{element.agent_status}}
                    </span>

                    <span class="td1">
                      {{element.agent_rate}}
                    </span>

                    <span class="td2">
                      {{element.phone}}
                    </span>
                    
                    <br>
                  </li>
              </ul>
              <br><br><br><br><br><br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import router from "@/router";

const company = sessionStorage.getItem("company");
var message = "";
var filter = "";

export default {
  name: "Agents",
  data() {
    return {
      showMenu: false,
      elements: [],
      company,
      message,
      filter,
      role: sessionStorage.getItem('role')
    }
  },
  computed: {
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    serializeForm(){
      
      let myForm = document.getElementById('search-form'); 
      let formData = new FormData(myForm);
      const data = {}; 
      // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
      }
      return(data);
    },
    async getData() {
      let response = await fetch(this.$server + '/apis/Agents');
      this.elements = await response.json();
      this.message = this.elements.length + ' Agents';
    },
    async newelement(type) {
      router.push({ name: type, params: {id: 0}});
    },
    async search() {
      var filter = document.getElementById('search').value;
      if (filter == '') {
        let response = await fetch(this.$server + '/apis/Agents');
        this.elements = await response.json();
        this.message = this.elements.length + ' Agents';
      } 
      else {   
        if (filter.length > 2) {  
          let response = await fetch(this.$server + '/apis/agent_search/' + filter);
          this.elements = await response.json();    
          this.message = this.elements.length + ' Agents';      
        }
      }
    },
    async connectrate(cmd) {
      navigator.clipboard.writeText(cmd);
      console.log('copied');
    },
  },
  components: { Navbar, ArgonButton },
  created() {
    this.getData();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    //setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>